import { render, staticRenderFns } from "./RecordChip.vue?vue&type=template&id=555276ee&scoped=true"
import script from "./RecordChip.vue?vue&type=script&lang=js"
export * from "./RecordChip.vue?vue&type=script&lang=js"
import style0 from "./RecordChip.vue?vue&type=style&index=0&id=555276ee&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "555276ee",
  null
  
)

export default component.exports